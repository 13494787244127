<script setup lang="ts">
import { Primitive, type PrimitiveProps } from 'radix-vue';
import { type HTMLAttributes } from 'vue';

import { cn } from '@/lib/utils';

import { type ButtonVariants, buttonVariants } from '.';

interface Props extends PrimitiveProps {
  variant?: ButtonVariants['variant'];
  size?: ButtonVariants['size'];
  class?: HTMLAttributes['class'];
  dataTestId?: string;
}

const props = withDefaults(defineProps<Props>(), {
  as: 'button',
});
</script>

<template>
  <Primitive
    :as="as"
    :as-child="asChild"
    :class="cn(buttonVariants({ variant, size }), props.class, 'gap-2')"
    :data-testid="dataTestId"
  >
    <slot />
  </Primitive>
</template>
