// stylesheets
import '@/assets/scss/main.scss';
// vendors css
import 'vue3-toastify/dist/index.css';
import 'vue3-easy-data-table/dist/style.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';

import { icons } from '@/components/utilities/Icons';
// directives
import { vClickOutside } from '@/directives/clickOutside';
import formatCurrency from '@/directives/currencyFormatting';
// plugins
import $auth from '@/plugins/authorization';
import posthogPlugin from '@/plugins/posthog'; //import the plugin.

import App from './App.vue';
import { ViyaGlobalComponents } from './components';
import router from './router';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App).use(pinia).use(router).use(posthogPlugin).use($auth);

for (const [key, comp] of Object.entries(ViyaGlobalComponents)) {
  app.component(key, comp);
}
for (const icon of icons) {
  library.add(icon);
}

const directives = {
  'click-outside': vClickOutside,
  currency: formatCurrency,
};

// combines directives defined in `directives object`
Object.entries(directives).forEach(([name, directive]) => {
  app.directive(name, directive);
});

app.mount('#app');

/**
 * When a new deployment occurs, the hosting service may delete the assets from previous deployments.
 * As a result, a user who visited your site before the new deployment might encounter an import error.
 * This error happens because the assets running on that user's device are outdated and it tries to import the corresponding old chunk,
 * which is deleted. This event is useful for addressing this situation.
 * https://vitejs.dev/guide/build#load-error-handling
 */
window.addEventListener(
  'vite:preloadError',
  () => {
    window.location.reload();
  },
  { passive: true }
);
